import Header from "./layout/header";
import Footer from "./layout/footer";
import { Component } from "react";
import styles from '../styles/sass/layout/Layout.module.scss'

export class Layout extends Component {
    render() {
        let { children } = this.props;
        return (
            <>
                <Header />
                <main className={styles.content}>{children}</main>
                <Footer />
            </>
        );
    }
}
