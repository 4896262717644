import Link from 'next/link'
import Image from 'next/image'

import Popup from '../popup'
import Nav from '../nav'
import MobileMenu from '../mobileMenu'
import styles from '../../styles/sass/layout/Header.module.scss'
import { useState, useEffect } from 'react'
import useWindowSize from '../../util/useWindowSize'
import { useRouter } from 'next/router';

import logo from '../../../public/img/header-logo.png';

export default function Header() {
    const [showPopup, setShowPopup] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const size = useWindowSize();
    const router = useRouter();

    useEffect(() => {
        if (showPopup || showMobileMenu) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'visible';
    }, [showPopup, showMobileMenu]);


    useEffect(() => {
        if (showMobileMenu) {
            document.querySelector('.' + styles.toggler).checked = false;
            setShowMobileMenu(false);
        }
    }, [router.reload]);

    return (
        <>
            <header className={[styles.header].join()}>
                <span>
                    <div className={styles.logoContainer}>
                        <Link href="/">
                            <span className={styles.logoImgContainer}>
                                <Image
                                    src={logo}
                                    placeholder='blur'
                                    alt="Szabó Jennifer logo"
                                    width={150}
                                />

                            </span>
                        </Link>
                    </div>
                </span>
                {size.width >= 1200 && <Nav mobile={false} />}
                {/* <span className={styles.cartIconContainer}>
                    <ion-icon name="bag-outline" onClick={() => setShowPopup(true)}></ion-icon>
                </span> */}
                <span>
                    {size.width < 1200 &&
                        <div className={styles.menuWrap}>
                            <input type="checkbox" className={styles.toggler} onChange={e => setShowMobileMenu(!showMobileMenu)} id="hamburger-menu-checkbox" aria-label='hamburger menü' />
                            <div className={styles.hamburger}><div>
                            </div></div>
                        </div>
                    }
                </span>


            </header>

            <Popup show={showPopup} setShowPopup={setShowPopup}>
            </Popup>
            {size.width < 1200 && <MobileMenu show={showMobileMenu}></MobileMenu>}

        </>
    );
}