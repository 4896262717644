import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'next/image';
import Link from 'next/link';
import styles from '../../styles/sass/layout/Footer.module.scss';


const Footer = () => {
    return (
        <footer className={styles.footer}>
            <ul className="flex flex-col md:flex-row gap-14 m-2 items-center justify-center text-2xl">
                <li>
                    <span className={styles.logoContainer}>
                        <Link href="/">
                            <span>
                                <Image
                                    src="/img/logo-inv.png"
                                    alt="logo"
                                    width={150}
                                    height={100}
                                />
                            </span>
                        </Link>
                    </span>
                </li>
                <li className={styles.footerContact}>
                    <Link href='/'>Főoldal</Link>
                </li>
                <li>
                    <div className='flex gap-14 md:flex-row'>
                        <div className={styles.footerContact}>
                            <a target="_blank" href="https://www.instagram.com/szabojennifer_pszichologus" aria-label="Instagram" >
                                <div>
                                    <FontAwesomeIcon icon={['fab', 'instagram']} className='w-6 text-white' />
                                </div>
                            </a>
                        </div>
                        <div className={styles.footerContact}>
                            <a href="https://www.facebook.com/olvasoMagam" target="_blank" aria-label="Facebook">
                                <div>
                                    <FontAwesomeIcon icon={['fab', 'facebook']} className='w-6 text-white' />
                                </div>
                            </a>

                        </div>
                        <div className={styles.footerContact}>
                            <a href="https://www.linkedin.com/in/jennifer-szab%C3%B3-3aba52156/" target="_blank" aria-label="Linkedin">
                                <div>
                                    <FontAwesomeIcon icon={['fab', 'linkedin']} className='w-6 text-white' />
                                </div>
                            </a>
                        </div>
                    </div>
                </li>
                <li className={styles.footerContact}>
                    <a href="mailto:kapcsolat@szabojennifer.hu" aria-label="Linkedin">
                        <div>
                            <FontAwesomeIcon icon={['fa', 'envelope']} className='w-6 text-white' />
                        </div>
                    </a>
                </li>
            </ul>
        </footer >
    );
}

export default Footer;