import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faCalendar, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import { Analytics } from '@vercel/analytics/react';
import { useEffect } from "react";
import { Layout } from '../components/layout';
import '../styles/globals.css';

const { library, config } = require('@fortawesome/fontawesome-svg-core');

config.autoAddCss = false;
library.add(faFacebook, faLinkedin, faInstagram, faEnvelope, faCalendar, faMapMarkerAlt);


function SzaboJenniferSite({ Component, pageProps }) {

  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(
          function (registration) {
            console.log("Service Worker registration successful with scope: ", registration.scope);
          },
          function (err) {
            console.log("Service Worker registration failed: ", err);
          }
        );
      });
    }
  }, [])

  return (<Layout>
    < Component {...pageProps} />
    <Analytics />
  </Layout >
  )

}

export default SzaboJenniferSite