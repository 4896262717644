import Link from 'next/link'
import styles from '../styles/sass/components/Nav.module.scss'

export default function Nav(props) {

    function smoothScroll(element) {
        var elementPosition = document.querySelector(element).offsetTop;
        document.querySelector(element).scrollIntoView(
            {
                top: elementPosition - 200,
                behavior: 'smooth'
            });
    }

    return (
        <nav className={styles.mainNav}>
            <ul className={styles.mainNavList}>
                <li className={styles.mainNavListItem}><Link href="/">KEZDŐLAP</Link></li>
                <li className={styles.mainNavListItem}
                    onClick={(e) => props.mobile && e.currentTarget.children[1].classList.toggle(styles.open)}>
                    <span>RÓLUNK
                        <ion-icon name="chevron-down-outline"></ion-icon>
                    </span>
                    <ul className={`${styles.subMenu}`}>
                        <li className={styles.subMenuListItem}><Link href="/rolam">Rólam</Link></li>
                        <li className={styles.subMenuListItem}><Link href="/rolad">Rólad</Link></li>
                    </ul>
                </li>
                <li className={`${styles.mainNavListItem}`}
                    onClick={(e) => props.mobile && e.currentTarget.children[1].classList.toggle(styles.open)}>
                    <span>SZOLGÁLTATÁSOK<ion-icon name="chevron-down-outline"></ion-icon></span>
                    <ul className={`${styles.subMenu} `}>
                        <li>
                            <Link href="/szolgaltatasok/szemelyes-tanacsadas">
                                <div className={styles.subMenuListItem}>Személyes tanácsadás</div>
                            </Link>
                        </li>
                        <li>
                            <Link href="/szolgaltatasok/online-tanacsadas">
                                <div className={styles.subMenuListItem}>Online tanácsadás</div>
                            </Link>
                        </li>
                        <li>
                            <Link href="/szolgaltatasok/csoport">
                                <div className={styles.subMenuListItem}>Csoport</div>
                            </Link>
                        </li>
                    </ul>
                </li>
                {/*<li className={styles.mainNavListItem}><Link href="/blog">BLOG</Link></li>*/}
                <li className={styles.mainNavListItem}><Link href="/kapcsolat">KAPCSOLAT</Link></li>
            </ul>
        </nav>
    )
}
